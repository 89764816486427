const appRoutes = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  optOutMarketing: '/opt-out-marketing',
  nurseProfile: 'profile',
  changePassword: 'change-password',
  updatePassword: 'update-password',
  logins: 'logins',
  addLogin: 'login/add',
  editLogin: 'login/edit',
  clinics: 'clinics',
  addClinic: 'clinic/add',
  addTemplate: 'clinic/template',
  bookings: 'bookings',
  onlineBooking: 'booking',
  bookingConfirmation: 'confirm-booking',
  bankDetails: 'bank-details',
  editBankDetails: 'edit-bank-details',
  addBankDetails: 'add-bank-details',
  editBooking: 'booking/edit',
  createBooking: ':patientId/bookings/create',
  paymentSummary: 'payment-summary',
  viewBooking: 'booking/view',
  patients: 'patients',
  addPatient: 'patient/add',
  practitionerConsents: 'practitioner-consents',
  editPractitionerConsents: 'practitioner-consent/edit',
  stocks: 'injectables-stock',
  addStock: 'injectables-stock/add',
  editStock: 'injectables-stock/edit',
  viewStock: 'injectables-stock/view',
  otherStocks: 'other-stock',
  addOtherStock: 'other-stock/add',
  editOtherStock: 'other-stock/edit',
  viewOtherStock: 'other-stock/view',
  productStocks: 'product-stock',
  addProductStock: 'product-stock/add',
  editProductStock: 'product-stock/edit',
  viewProductStock: 'product-stock/view',
  legal: 'legal',
  marketing: 'marketing',
  reporting: 'reporting',
  nurseShifts: 'shifts',
  addPayment: 'payment',
  keyPerformance: 'key-performance',
  keyPerformanceActions: 'key-performance-actions',
  prDashboard: '/pr/patient',
  prConsentForms: '/pr/consentForms',
  prMedicalHistory: '/pr/medicalhistory',
  prPractitionerConsents: '/pr/practitionerconsents',
  prEditPractitionerConsents: '/pr/editpractitionerconsents',
  prBookingHistory: '/pr/booking/history',
  prS4DrugProtocols: '/pr/s4-drug-protocols',
  prAddS4DrugProtocolForm: '/pr/s4-drug-protocols/create',
  prViewS4DrugProtocol: '/pr/s4-drug-protocols/view',
  prOtherProtocols: '/pr/other-protocols',
  prAddOtherProtocol: '/pr/other-protocols/create',
  prViewOtherProtocol: '/pr/other-protocols/view',
  prEditBookingHistory: '/pr/bookinghistory/edit',
  prCreateBooking: '/pr/:patientId/bookings/create',
  prViewBookingHistory: '/pr/bookinghistory/view',
  prAddNewConsentForm: '/pr/addNewConsentForm',
  prPayment: '/pr/payment',
};

export default appRoutes;
