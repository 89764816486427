import Axios from 'axios';
import APP_CONTANTS from '../utils/constants';
import * as storageApi from './storage';

const apiRoot = APP_CONTANTS.apiUrl;

const authorizationHeader = () => {
  const token = storageApi.get('token');
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  return {};
};

const getPath = (path) => `${apiRoot}${path}`;

const getHeaders = (contentType) => {
  const headers = {
    ...authorizationHeader(),
  };

  if (contentType === 'json') {
    headers['Content-Type'] = 'application/json';
  }

  return {
    headers: headers,
  };
};

const generateQueryString = (payload) => {
  if (!payload) {
    return '';
  }

  return Object.keys(payload).reduce((memo, key) => {
    if (payload[key] === undefined) {
      return memo;
    }

    if (Array.isArray(payload[key])) {
      return (
        memo +
        payload[key].reduce((arrMemo, v) => {
          if (typeof v === 'object') {
            // eslint-disable-next-line no-param-reassign
            v = Object.values(v).join('|');
          }
          return `${arrMemo}${encodeURIComponent(key)}[]=${encodeURIComponent(v)}&`;
        }, '')
      );
    }

    if (payload[key] !== null && typeof payload[key] === 'object') {
      return `${memo}${key}=${JSON.stringify(payload[key])}&`;
    }

    return `${memo}${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}&`;
  }, '?');
};

const parseJson = (response) => {
  if (response.status === 204) {
    return null;
  }

  if (response.status === 200 || response.status === 201) {
    return response.data;
  }
};

const errorResponse = (error) => {
  const customError = {
    data: error.response.data,
    status: error.response.status,
    message: error.message,
  };
  throw customError;
};

const validatePlainContent = (response) => {
  if (response.ok) {
    return response;
  }
  const message = 'Error validating plain content';
  throw new Error(message);
};
const parseBlob = (response) => response.blob();

// Add a request interceptor
Axios.interceptors.request.use(
  (config) => {
    const token = storageApi.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const post = (path, payload) => {
  const contentType =
    typeof payload === 'object' && !(payload instanceof FormData)
      ? 'application/json'
      : 'multipart/form-data';
  return Axios.post(getPath(path), payload, getHeaders(contentType))
    .then(parseJson)
    .catch(errorResponse);
};

export const put = (path, payload) =>
  Axios.put(getPath(path), payload, getHeaders()).then(parseJson).catch(errorResponse);

export const get = (path, payload) =>
  Axios.get(`${getPath(path)}${generateQueryString(payload)}`, getHeaders())
    .then(parseJson)
    .catch(errorResponse);

export const getPlainContent = (path, payload) =>
  Axios.get(`${getPath(path)}${generateQueryString(payload)}`, getHeaders()).then(
    validatePlainContent
  );

export const getRaw = (path, payload) =>
  Axios.get(`${getPath(path)}${generateQueryString(payload)}`, getHeaders()).then(parseBlob);

export const del = (path, payload) =>
  Axios.delete(getPath(path), { data: payload, ...getHeaders() })
    .then(parseJson)
    .catch(errorResponse);

export const patch = (path, payload) =>
  Axios.patch(getPath(path), payload, getHeaders()).then(parseJson).catch(errorResponse);
