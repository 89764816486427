import { Box, Typography, Hidden } from '@mui/material';

const CallInfo = () => (
  <>
    <Typography component="span" mr={1}>
      Support
    </Typography>
    <i className="fas fa-phone-alt" />
    <a
      href="tel:0735555888"
      style={{ marginRight: 16, marginLeft: 4 }}
      className="link insta-link-default insta-link-orange thin-font">
      07 3555 5888
    </a>
  </>
);
const EmailInfo = () => (
  <>
    <i style={{ marginRight: 4 }} className="fas fa-envelope" />
    <a
      href="mailTo:support@beautybook.software"
      style={{ marginRight: 16, marginLeft: 4 }}
      className="link insta-link-default insta-link-orange thin-font">
      support@beautybook.software
    </a>
  </>
);
const SupportInfo = ({ isAuthenticated }) => {
  return (
    <>
      {isAuthenticated ? (
        <Box className="support-call">
          <CallInfo />
        </Box>
      ) : (
        <CallInfo />
      )}
      {!isAuthenticated && (
        <Hidden xsUp mdDown>
          <Box width={1} />
        </Hidden>
      )}
      {isAuthenticated ? (
        <Box className="support-email">
          <EmailInfo />
        </Box>
      ) : (
        <EmailInfo />
      )}
    </>
  );
};

export default SupportInfo;
