import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { pdfjs } from 'react-pdf';
import * as serviceWorker from './serviceWorker';
import './assets/fontawesome/css/all.min.css';
import './style/index.scss';

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();
