import { createContext, useContext, useState } from 'react';
import * as storageApi from '../api/storage';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const token = !!storageApi.get('token');
  const storedUser = JSON.parse(storageApi.get('user'));
  const [isAuthenticated, setIsAuthenticated] = useState(token);
  const [user, setUser] = useState(storedUser || null);

  const logout = () => {
    storageApi.del('token');
    storageApi.del('user');
    setUser(null);
    setIsAuthenticated(false);
  };
  const setUserAndStorage = (newUser) => {
    setUser(newUser);
    if (newUser) {
      storageApi.set('user', JSON.stringify(newUser));
    } else {
      storageApi.del('user');
    }
  };

  const contextValues = {
    user,
    setUser: setUserAndStorage,
    logout,
    isAuthenticated,
    setIsAuthenticated,
  };

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export const useAuth = () => useContext(AuthContext);
