import { Box, Typography } from '@mui/material';
import BBModal from '../UI/BBModal';
import appRoutes from '../../routes/appRoutes';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
const LogOutModal = ({ openModal, onClose }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const logOut = () => {
    const openPRTabList = window.updateOpenPRTabList || [];
    openPRTabList.forEach((openPRTab) => {
      openPRTab.close();
    });
    logout();
    navigate(appRoutes.login);
  };

  return (
    <BBModal
      openModal={openModal}
      title="Logout"
      cancelText="Stay Logged In"
      cancelEvent={onClose}
      okayText="Logout"
      okayEvent={logOut}
      useOneButton={false}
      modalBodyContent={
        <Box>
          <Typography mb={1}>Are you sure you want to logout?</Typography>
          <Typography>Any Patient Records open in other tabs will automatically close</Typography>
        </Box>
      }
    />
  );
};

export default LogOutModal;
