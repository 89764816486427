import { Typography } from '@mui/material';
import BBModal from '../components/UI/BBModal';

const ForgotPasswordModal = ({ open, onClose }) => {
  return (
    <BBModal
      title="Beauty Book"
      openModal={open}
      cancelEvent={onClose}
      cancelText="Okay"
      useOneButton
      modalBodyContent={
        <Typography>Password reset link has been sent to your registered email</Typography>
      }
    />
  );
};

export default ForgotPasswordModal;
