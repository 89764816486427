// Don't modify anything except the last testing one's

let APP_CONTANTS = {};

// For new staging
if (window.location.origin.indexOf('staging') > -1) {
  // APP_CONTANTS.apiUrl = 'https://staging-api.beautybook.software';
  // APP_CONTANTS.prUrl = 'https://staging-app.beautybook.software/pr/patient';
  // APP_CONTANTS.bookingUrl = 'https://staging-booking.beautybook.software/';
  // APP_CONTANTS.priceListURL = 'https://staging-booking.beautybook.software/price/';
  APP_CONTANTS.apiUrl = 'https://staging-api.instanttouch.com.au';
  APP_CONTANTS.prUrl = 'https://staging-app.instanttouch.com.au/pr/patient';
  APP_CONTANTS.bookingUrl = 'https://staging-booking.instanttouch.com.au/';
  APP_CONTANTS.priceListURL = 'https://staging-booking.instanttouch.com.au/price/';
}
// For new dev
else if (window.location.origin.indexOf('dev') > -1) {
  APP_CONTANTS.apiUrl = 'https://dev-api.instanttouch.com.au';
  APP_CONTANTS.prUrl = 'https://dev-app.instanttouch.com.au/pr/patient';
  APP_CONTANTS.bookingUrl = 'https://dev-booking.instanttouch.com.au/';
  APP_CONTANTS.priceListURL = 'https://dev-booking.instanttouch.com.au/price/';
}
// for live
else if (window.location.origin.indexOf('app') > -1) {
  APP_CONTANTS.apiUrl = 'https://api.beautybook.software';
  APP_CONTANTS.prUrl = 'https://app.beautybook.software/pr/patient';
  APP_CONTANTS.bookingUrl = 'https://booking.beautybook.software/';
  APP_CONTANTS.priceListURL = 'https://booking.beautybook.software/price/';
}
// For testing
else {
  APP_CONTANTS.apiUrl = 'http://localhost:4000';
  // APP_CONTANTS.apiUrl = "https://dev-api.instanttouch.com.au";
  // APP_CONTANTS.apiUrl = "http://192.168.1.4:4000";
  APP_CONTANTS.prUrl = 'http://localhost:3002/pr/patient';
  APP_CONTANTS.bookingUrl = 'http://localhost:3001/';
  APP_CONTANTS.priceListURL = 'http://localhost:3001/price/';
}

export default APP_CONTANTS;
