import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  // Using Redux to get the commonReducers state
  const commonReducers = useSelector((state) => state.commonReducers);

  // Update the state with the privacy policy from Redux
  useEffect(() => {
    if (commonReducers.constantsData && commonReducers.constantsData.adminPrivacyPolicy) {
      setPrivacyPolicy(commonReducers.constantsData.adminPrivacyPolicy);
    }
  }, [commonReducers]);

  return (
    <Box width={1} bgcolor="#fff" className="footer">
      <Box px={2} pt={2} pb={1} width={1} borderTop="1px solid #dee2e6">
        <Typography component="span" sx={{ fontSize: 12 }}>
          Beauty Book®
        </Typography>
        <Typography component="span" sx={{ fontSize: 12 }}>
          &trade;
        </Typography>
        <a
          target="_blank"
          href={privacyPolicy}
          style={{ textDecoration: 'none', paddingLeft: 12 }}
          className="link text-primary cursor-pointer font-12"
          rel="noreferrer">
          Privacy Policy
        </a>
      </Box>
    </Box>
  );
};

export default Footer;
