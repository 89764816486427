import React from 'react';
import { CircularProgress } from '@mui/material';

export default function Loading() {
  return (
    <div className="lazy-loading-component">
      <CircularProgress disableShrink className="primary-loader" style={{ margin: 'auto' }} />
    </div>
  );
}
