import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, FormControl, FormLabel, TextField, Typography } from '@mui/material';

import appRoutes from '../../routes/appRoutes';
import BBButton from '../UI/BBButton';

const ForgotPasswordForm = (props) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(7, 'Email must be of minimum length 7')
        .email('Please provide a valid Email Address')
        .required('Please enter the Email Address'),
    }),
    onSubmit: async (values) => {
      await props.onFormSubmit(values);
    },
  });
  return (
    <Box component="form" display="flex" flexDirection="column" onSubmit={formik.handleSubmit}>
      <Box mb={3}>
        <Typography>
          A link will be sent to your email address so you can reset your password
        </Typography>
      </Box>

      <FormControl fullWidth>
        <FormLabel required sx={{ textAlign: 'left' }}>
          Email Address
        </FormLabel>
        <TextField
          id="email"
          name="email"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </FormControl>
      <Box mt={3}>
        <BBButton text="Submit" loadingText="Submitting..." loading={formik.isSubmitting} />
      </Box>
      <br />
      <NavLink
        to={appRoutes.login}
        style={{ color: '#999', fontSize: '14px', textDecoration: 'none' }}
        className="color-dark-grey"
        href="/">
        <span>Back to Login</span>
      </NavLink>
      <Box mt={1} className="error">
        {props.serverErrorMsg}
      </Box>
    </Box>
  );
};

export default ForgotPasswordForm;

ForgotPasswordForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string,
};
