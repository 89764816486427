import { createTheme } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#fea889',
      light: '#fecdbc',
      contrastText: '#fff',
    },
    secondary: {
      main: '#a09f9fd6',
      light: '#e6e6e6',
      dark: '#777575',
      contrastText: '#fff',
    },
    error: { main: '#e84c25' },
    yellow: {
      main: '#f5ab35',
      light: '#f8c36d',
      dark: '#A29415',
      contrastText: '#fff',
    },
    info: {
      main: '#70d0c6',
      contrastText: '#fff',
    },
    blue: {
      main: '#2e496a',
    },
  },

  // Typoghraphy style overriding
  typography: {
    fontFamily: 'AvenirNextRegular',
    lineHeight: 1.5,
    h4: {
      fontSize: 25,
      color: '#656565',
    },
    h5: {
      fontSize: 20,
      color: '#656565',
    },
    body1: {
      fontSize: 15,
      color: '#656565',
    },
    body2: {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  components: {
    // Calender Styling
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          paddingTop: 16,
          '& .MuiPickersLayout-toolbar': {
            backgroundColor: '#fea889',
            '.css-sj5dps-MuiTypography-root': {
              display: 'none',
            },
          },
          '& .MuiDatePickerToolbar-title': {
            color: '#fff',
            fontSize: 34,
            fontWeight: 600,
            textAlign: 'left',
          },
        },
      },
    },

    // Card style override
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'unset',
        },
      },
    },

    // Modal style override
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paperWidthSm': {
            width: 500,
          },
          '& .MuiDialog-paperWidthMd': {
            width: 800,
          },
          '& .MuiDialog-scrollPaper': {
            alignItems: 'flex-start',
          },
          '& .MuiDialogTitle-root': {
            backgroundColor: '#fea889',
            color: '#fff',
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '18px',
            padding: '16px',
          },
          '& .MuiTypography-h6': {
            fontWeight: 600,
            fontSize: '18px',
            fontFamily: 'AvenirNextRegular',
          },
          '& .MuiDialogContent-root': {
            padding: '16px',
            color: '#333333',
            textAlign: 'center',
            fontSize: '15px',
            marginTop: 16,
          },
          '& .MuiDialogActions-root': {
            justifyContent: 'center',
            borderTop: 'none',
            padding: '12px',
            paddingBottom: '20px',
          },
        },
      },
    },

    // Table Style overriding
    MuiTableContainer: {
      styleOverrides: {
        root: {
          // maxHeight: 500,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          tr: {
            th: {
              fontSize: '13px',
              fontWeight: 600,
              fontStyle: 'normal',
              fontStretch: 'normal',
              letterSpacing: 'normal',
              color: 'rgba(0, 0, 0, 0.87)',
              backgroundColor: '#fafafa',
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          tr: {
            td: {
              textAlign: 'left',
              fontSize: '14px',
              lineHeight: 1.5,
              color: 'rgba(0, 0, 0, 0.87)',
            },
          },
        },
      },
    },

    MuiChip: {
      variants: [
        {
          props: { variant: 'square' },
          style: {
            borderRadius: 4,
            minWidth: '70px',
            fontSize: '75%',
            fontWeight: 700,
            lineHeight: 1,
          },
        },
      ],
    },

    // Button
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const color = ownerState.color
            ? theme.palette[ownerState.color].main
            : theme.palette.primary.main;
          return {
            fontFamily: 'AvenirNextMedium',
            textTransform: 'none',
            color: '#fff',
            borderRadius: '4px',
            fontStyle: 'normal',
            fontStretch: 'normal',
            minWidth: 130,
            transition: '0.3s',
            border: `1px solid ${color}`,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#fff',
              color: `${color}`,
              boxShadow: 'none',
              outline: 'none',
            },
            '&:active': {
              boxShadow: 'none',
              backgroundColor: '#fff',
              border: `1px solid ${color}`,
            },
            '&:focus': {
              boxShadow: 'none',
            },
            '&:disabled': {
              borderColor: '#ddd',
              backgroundColor: '#f5f5f5',
              color: '#aaa',
            },
            '& .MuiButton-startIcon': {
              paddingRight: 6,
              margin: 0,
              i: {
                fontSize: 14,
                fontWeight: 800,
              },
            },
          };
        },
      },
      defaultProps: {
        variant: 'contained',
        disableRipple: true,
      },
    },

    // Form fields overriding

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            minWidth: 0,
          },
          '& .MuiOutlinedInput-root': {
            paddingRight: '16px!important',
            padding: '8px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            marginTop: 0,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: 8,
          color: 'rgb(153, 153, 153)',
          '& .MuiFormLabel-asterisk': {
            color: '#d32f2f',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#E0E3E7',
          '--TextField-brandBorderFocusedColor': '#fff',
          '--TextField-errorColor': '#f44336',
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      defaultProps: {
        size: '',
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline:focused': {
            borderColor: '#fea889',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E0E3E7',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: '#E0E3E7',
          },
          '& .MuiOutlinedInput-notchedOutline:after': {
            // borderColor: '#fea889',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E0E3E7',
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: '1px solid #E0E3E7',
          },
          '&:hover:not(.Mui-disabled .Mui-error):before': {
            borderBottomWidth: '1px',
            borderBottomColor: '#E0E3E7',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          marginLeft: 5,
          color: 'var(--TextField-errorColor)',
          '&.Mui-error': {
            fontSize: 14,
            color: 'var(--TextField-errorColor)',
          },
        },
      },
    },
  },
});
