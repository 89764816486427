import { lazy } from 'react';
import appRoutes from './appRoutes';
const AddOtherStockPage = lazy(() => import('../pages/Stocks/OtherStocks/AddStockPage'));
const AddInjectableStockPage = lazy(() => import('../pages/Stocks/InjectableStocks/AddStockPage'));
const ViewInjectableStockPage = lazy(
  () => import('../pages/Stocks/InjectableStocks/ViewStockPage')
);
const ViewOtherStockPage = lazy(() => import('../pages/Stocks/OtherStocks/ViewStockPage'));
const OtherStockPage = lazy(() => import('../pages/Stocks/OtherStocks/StockPage'));
const InjectableStockPage = lazy(() => import('../pages/Stocks/InjectableStocks/StockPage'));
const EditOtherStockPage = lazy(() => import('../pages/Stocks/OtherStocks/EditStockPage'));
const EditInjectableStockPage = lazy(
  () => import('../pages/Stocks/InjectableStocks/EditStockPage')
);

const AddProductStockPage = lazy(() => import('../pages/Stocks/ProductStocks/AddStockPage'));
const ViewProductStockPage = lazy(() => import('../pages/Stocks/ProductStocks/ViewStockPage'));
const ProductStockPage = lazy(() => import('../pages/Stocks/ProductStocks/StockPage'));
const EditProductStockPage = lazy(() => import('../pages/Stocks/ProductStocks/EditStockPage'));

// Nurse pages
const BookingPage = lazy(() => import('../pages/Booking/Booking')); // booking page
const EditBookingPage = lazy(() => import('../pages/Booking/EditBooking')); // edit booking page
const ViewBookingPage = lazy(() => import('../pages/Booking/ViewBooking')); // view booking page
const PatientsPage = lazy(() => import('../pages/Patients/Patients')); // patients list page
const PractitionerConsentsPage = lazy(
  () => import('../pages/PractitionerConsents/PractitionerConsents')
); // Practitioner consents page
const AddPatientPage = lazy(() => import('../pages/Patients/AddPatientPage')); // Add New patient page
const LegalPage = lazy(() => import('../pages/Legal/LegalPage')); // Nurse Legal Page
const NurseProfilePage = lazy(() => import('../pages/Profile')); //Nurse Profile

const EditPractitionerConsents = lazy(
  () => import('../pages/PractitionerConsents/EditPractitionerConsents')
);

const MarketingPage = lazy(() => import('../pages/Marketing')); // Nurse Marketing Page
const ChangePasswordPage = lazy(() => import('../pages/ChangePassword')); //Change password page
const UpdatePasswordPage = lazy(() => import('../pages/UpdatePassword')); //Update password page

const AddPaymentPage = lazy(() => import('../pages/AddPayment')); // Nurse payment
const ReportingPage = lazy(() => import('../pages/Reporting')); // For admin users

const LoginsPage = lazy(() => import('../pages/Users/UsersPage')); // Super Admin Only
const AddLoginsPage = lazy(() => import('../pages/Users/AddUsersPage')); // Super Admin Only
const ClinicsPage = lazy(() => import('../pages/Clinics/ClinicsPage')); // Super Admin Only
const AddClinicsPage = lazy(() => import('../pages/Clinics/AddClinicsPage')); // Super Admin Only
const KeyPerformances = lazy(() => import('../pages/KeyPerformances')); // Super Admin Only
const KeyPerformanceActions = lazy(() => import('../pages/KeyPerformanceActions')); // Super Admin Only

const BankDetailsPage = lazy(() => import('../pages/BankDetails/index'));
const EditBankDetailsPage = lazy(() => import('../pages/BankDetails/EditBankDetails'));

const PaymentSummaryPage = lazy(() => import('../pages/PaymentSummary'));
const NurseShiftsPage = lazy(() => import('../pages/NurseShifts'));

const DashboardRoutes = [
  { path: appRoutes.logins, element: <LoginsPage /> },
  { path: appRoutes.keyPerformance, element: <KeyPerformances /> },
  { path: appRoutes.keyPerformanceActions, element: <KeyPerformanceActions /> },
  { path: appRoutes.addLogin, element: <AddLoginsPage /> },
  { path: `${appRoutes.editLogin}/:name`, element: <AddLoginsPage /> },
  { path: appRoutes.bookings, element: <BookingPage /> },
  { path: appRoutes.editBooking + '/:id', element: <EditBookingPage /> },
  { path: appRoutes.createBooking, element: <EditBookingPage /> },
  { path: appRoutes.addPayment + '/:id', element: <AddPaymentPage /> },
  { path: appRoutes.bankDetails, element: <BankDetailsPage /> },
  { path: appRoutes.addBankDetails, element: <EditBankDetailsPage /> },
  { path: appRoutes.editBankDetails, element: <EditBankDetailsPage /> },
  { path: appRoutes.paymentSummary, element: <PaymentSummaryPage /> },
  { path: appRoutes.viewBooking + '/:id', element: <ViewBookingPage /> },
  { path: appRoutes.patients, element: <PatientsPage /> },
  { path: appRoutes.addPatient, element: <AddPatientPage /> },
  { path: appRoutes.editPractitionerConsents, element: <EditPractitionerConsents /> },
  { path: appRoutes.practitionerConsents, element: <PractitionerConsentsPage /> },
  { path: appRoutes.stocks, element: <InjectableStockPage /> },
  { path: appRoutes.otherStocks, element: <OtherStockPage /> },
  { path: `${appRoutes.editStock}/:_id`, element: <EditInjectableStockPage /> },
  { path: `${appRoutes.editOtherStock}/:_id`, element: <EditOtherStockPage /> },
  { path: `${appRoutes.viewStock}/:id`, element: <ViewInjectableStockPage /> },
  { path: `${appRoutes.viewOtherStock}/:id`, element: <ViewOtherStockPage /> },
  { path: appRoutes.addStock, element: <AddInjectableStockPage /> },
  { path: appRoutes.addOtherStock, element: <AddOtherStockPage /> },
  { path: appRoutes.productStocks, element: <ProductStockPage /> },
  { path: appRoutes.addProductStock, element: <AddProductStockPage /> },
  { path: `${appRoutes.editProductStock}/:_id`, element: <EditProductStockPage /> },
  { path: `${appRoutes.viewProductStock}/:id`, element: <ViewProductStockPage /> },
  { path: appRoutes.nurseProfile, element: <NurseProfilePage /> },
  { path: appRoutes.changePassword, element: <ChangePasswordPage /> },
  { path: appRoutes.updatePassword, element: <UpdatePasswordPage /> },
  { path: appRoutes.marketing, element: <MarketingPage /> },
  { path: appRoutes.legal, element: <LegalPage /> },
  { path: appRoutes.reporting, element: <ReportingPage /> },
  { path: appRoutes.clinics, element: <ClinicsPage /> },
  { path: appRoutes.addClinic, element: <AddClinicsPage /> },
  { path: appRoutes.nurseShifts, element: <NurseShiftsPage /> },
];
export default DashboardRoutes;
