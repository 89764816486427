import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, AppBar, Hidden } from '@mui/material';

import SupportInfo from './SupportInfo';
import UserMenu from './UserMenu';
import appRoutes from '../../routes/appRoutes';
import PRSearchbar from '../PRSearchbar/PRSearchbar';

import { useAuth } from '../../context/AuthContext';

import FullLogo from '../../assets/images/BeautyBookLogo.svg';
const TopNavbar = ({ ipad, openNavDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isAuthenticated } = useAuth();
  const { prPatientId } = useSelector((state) => state.commonReducers);

  const isPR = location.pathname.includes('/pr/') ? true : false;
  const goToLink = () => {
    if (isPR) {
      navigate(`${appRoutes.prDashboard}/${prPatientId}`);
    } else {
      const routeMap = {
        super_admin: appRoutes.logins,
        doctor: appRoutes.practitionerConsents,
        default: appRoutes.bookings,
      };
      navigate(routeMap[user?.userType] || routeMap.default);
    }
  };

  const Logo = () => (
    <NavLink to="/">
      <Hidden smDown>
        <img src={FullLogo} alt="Beauty Book Logo" className="full-logo" />
      </Hidden>
      <Hidden smUp>
        <img src={FullLogo} alt="Beauty Book Logo" className="small-logo" />
      </Hidden>
    </NavLink>
  );

  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
        className="top-nav-container top-nav top-nav-loggedIn pr-top-nav">
        {isAuthenticated ? (
          <>
            {ipad && (
              <i
                style={{ marginLeft: 8, marginRight: 16 }}
                className="fas fa-bars font-18 primary-color"
                onClick={() => openNavDrawer(true)}
              />
            )}
            {!ipad && (
              <img
                onClick={goToLink}
                src={FullLogo}
                alt="Beauty Book Logo"
                className="full-logo"
                style={{ marginRight: 16 }}
              />
            )}
            <Hidden xsUp>
              <Box width={1} />
            </Hidden>
            <Box mx="auto" ml={8} className="pr-search-input-nav">
              <PRSearchbar />
            </Box>
            <Box width={1} pl={1} pr={isPR ? 2 : 0} className="navbar-text">
              <Box className="contact">
                <SupportInfo isAuthenticated />
              </Box>
            </Box>
            {!isPR && (
              <Box className="top-nav-right">
                <UserMenu />
              </Box>
            )}
          </>
        ) : (
          <Grid container alignItems="center">
            <Hidden smDown>
              <Grid item sm={1} />
            </Hidden>
            <Grid item xs={4} sm={2}>
              <Logo />
            </Grid>
            <Hidden smDown>
              <Grid item sm={4} />
            </Hidden>
            <Grid item xs={8} sm={5}>
              <Box width={1} pl={1}>
                <SupportInfo />
              </Box>
            </Grid>
          </Grid>
        )}
      </AppBar>
    </>
  );
};

export default TopNavbar;
