import React, { Fragment } from 'react';

import TopNavbar from '../components/Common/TopNavbar';
import Footer from '../components/Common/Footer';

const PublicLayout = (props) => {
  return (
    <Fragment>
      <TopNavbar />
      <div className="public-layout-container">{props.children}</div>
      <Footer />
    </Fragment>
  );
};

export default PublicLayout;
