import { Typography } from '@mui/material';
import BBModal from '../components/UI/BBModal';

const ResetPasswordModal = ({ open, onClose, modalText }) => {
  return (
    <BBModal
      title="Beauty Book"
      openModal={open}
      cancelEvent={onClose}
      cancelText="Okay"
      useOneButton
      modalBodyContent={
        <Typography>{modalText ? modalText : 'New Password Updated Successfully'}</Typography>
      }
    />
  );
};

export default ResetPasswordModal;
