/* eslint-disable */
/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import {
  NOTIFICATION_NUMBER,
  LOGIN,
  LOGOUT,
  EDIT_CONTACT_DETAILS,
  PR_PATIENT_ID,
  HEAD_CLINIC_DATA,
  PR_PATIENT_DATA,
  CONSTANTS_DATA,
  SET_USER_BANK_DETAILS,
  CLINICS_DATA,
  SET_PR_PATIENT_WARNINGS,
  SET_ALL_PROVIDERS_FROM_HEAD_CLINIC,
  BOOKING_FILTERS,
  STOCK_FILTERS,
} from '../constants/commonConstant';

const initialState = {
  totalNotification: 0, // not used for now - we will remove this
  auth: null, // store all user data in this
  isAuthenticated: false, // used to check user is logged in or not
  prContactDetailsEdit: false, // default contact details edit false
  prPatientId: null,
  prPatientData: null,
  headClinicData: null,
  clinicsData: null,
  prPatientWarningData: null,
  allProvidersFromHeadClinic: [],
  bookingFilters: {},
  stockFilters: {
    dateReceivedStart: null,
    dateReceivedEnd: null,
    dateExpiredStart: null,
    dateExpiredEnd: null,
    clinic: 'Select Clinic',
    status: 'Select Status',
    addedBy: 'Select Added By',
    drugName: 'Select Drug Name',
    premiumInventoryName: 'Select Premium Inventory Name',
    productName: 'Select Product Name',
    treatmentType: 'Select Treatment Type',
    searchKeyword: '',
    inactive: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_NUMBER:
      return {
        ...state,
        totalNotification: action.payload.totalNotification,
      };
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        auth: action.payload.auth,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        auth: null,
      };
    case EDIT_CONTACT_DETAILS:
      return {
        ...state,
        prContactDetailsEdit: action.payload.isEditEnable,
      };
    case PR_PATIENT_ID:
      return {
        ...state,
        prPatientId: action.payload,
      };

    case PR_PATIENT_DATA:
      return {
        ...state,
        prPatientData: action.payload,
      };
    case HEAD_CLINIC_DATA:
      const headClinic = action.payload;

      const sortedClinics = headClinic.clinicIds
        .filter((item) => !item.isBlocked)
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

      const headClinicData = {
        ...headClinic,
        clinicIds: sortedClinics,
      };

      return {
        ...state,
        headClinicData,
      };
    case CLINICS_DATA:
      return {
        ...state,
        clinicsData: action.payload.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }),
      };
    case CONSTANTS_DATA:
      return {
        ...state,
        constantsData: action.payload,
      };
    case SET_USER_BANK_DETAILS:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            bankDetails: action.payload,
          },
        },
      };
    case SET_PR_PATIENT_WARNINGS:
      return {
        ...state,
        prPatientWarningData: action.payload,
      };
    case SET_ALL_PROVIDERS_FROM_HEAD_CLINIC:
      return {
        ...state,
        allProvidersFromHeadClinic: action.payload,
      };
    case BOOKING_FILTERS:
      return {
        ...state,
        bookingFilters: action.payload,
      };
    case STOCK_FILTERS:
      return {
        ...state,
        stockFilters: action.payload,
      };
    default:
      return state;
  }
}
