import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import DashboardRoutes from './DashboardRoutes';
import PRRoutes from './PRRoutes';
import LoginPage from '../pages/Login';
import ForgotPassPage from '../pages/ForgotPassword';
import ResetPassPage from '../pages/ResetPassword';
import OptOutMarketingPage from '../pages/OptOutMarketingPage';
import RestrictedRoute from './RestrictedRoute';
import PublicRoute from './PublicRoute';

// Routes
import appRoutes from './appRoutes';
import { USER_TYPE } from '../utils/constants/common';
import { useAuth } from '../context/AuthContext';

// Loading Component
import Loading from '../components/UI/Loading';

//Layout Components
const DashboardLayout = lazy(() => import('../layouts/DashboardLayout'));
const PRDashboardLayout = lazy(() => import('../layouts/PRLayout'));

const AppRoutes = () => {
  const { isAuthenticated, user } = useAuth();
  const userType = user && user?.userType ? user.userType : '';
  let baseName = userType === 'super_admin' ? 'superadmin' : userType;
  const forcePasswordChange = user?.forcePasswordChange;

  const pathRoute = (userType) => {
    let path = '';
    if (userType === USER_TYPE.SUPER_ADMIN) {
      path = `/superadmin/${forcePasswordChange ? appRoutes.updatePassword : appRoutes.logins}`;
      return path;
    }
    if (userType === USER_TYPE.DOCTOR) {
      path = `/${userType}/${forcePasswordChange ? appRoutes.updatePassword : appRoutes.practitionerConsents}`;
      return path;
    }
    if (userType) {
      path = `/${userType}/${forcePasswordChange ? appRoutes.updatePassword : appRoutes.bookings}`;
      return path;
    }
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? <Navigate to={pathRoute(userType)} /> : <Navigate to="/login" />
          }
        />
        <Route path={appRoutes.optOutMarketing + '/:id'} element={<OptOutMarketingPage />} />
        <Route element={<PublicRoute />}>
          <Route path={appRoutes.login} element={<LoginPage />} />
          <Route path={appRoutes.forgotPassword} element={<ForgotPassPage />} />
          <Route path={`${appRoutes.resetPassword}/:token`} element={<ResetPassPage />} />
        </Route>
        <Route element={<RestrictedRoute />}>
          <Route
            path={`${baseName}`}
            element={
              <Suspense fallback={<Loading />}>
                <DashboardLayout />
              </Suspense>
            }>
            {DashboardRoutes.map(({ path, element }) => (
              <Route
                key={path}
                path={path}
                element={<Suspense fallback={<Loading />}>{element}</Suspense>}
              />
            ))}
          </Route>
          <Route
            path="/pr"
            element={
              <Suspense fallback={<Loading />}>
                <PRDashboardLayout />
              </Suspense>
            }>
            {PRRoutes.map(({ path, element }) => (
              <Route
                key={path}
                path={path}
                element={<Suspense fallback={<Loading />}>{element}</Suspense>}
              />
            ))}
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};
export default AppRoutes;
