import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';

const SearchInput = ({ className, ...props }) => {
  return (
    <TextField
      className={'search-input' + ' ' + className}
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <i className="fas fa-search" style={{ color: '#999' }}></i>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
};

export default SearchInput;
