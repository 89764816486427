import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  List,
  Avatar,
  ListItem,
  Typography,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';

import SearchInput from '../UI/SearchInput';
import APP_CONTANTS from '../../utils/constants';
import { getPatient } from '../../api/patients';
import { useAuth } from '../../context/AuthContext';

const PRSearchbar = () => {
  const searchInputContainerRef = useRef();
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { user } = useAuth();

  const timeoutRef = useRef(null);

  const resetSearchParameters = () => {
    setPatientList([]);
    setLoading(false);
    setIsDrawerOpen(false);
    setSearchText('');
  };

  const getPatientRecords = async (text) => {
    try {
      setLoading(true);
      setIsDrawerOpen(true);
      const dataToSend = {
        searchTerm: text,
        limit: 20,
        skip: 0,
        clinicIds: user?.clinicIds || [],
      };
      const res = await getPatient(dataToSend);
      setPatientList(res.data.doc);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const searchPatientRecord = (event) => {
    const text = event.target.value;

    setSearchText(text);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      if (text.trim().length > 0) {
        getPatientRecords(text);
      } else {
        setSearchText('');
        setPatientList([]);
        setIsDrawerOpen(false);
      }
    }, 700);
  };

  const selectedPatient = (id) => {
    const tab = window.open(APP_CONTANTS.prUrl + '/' + id, '_blank');
    const prevUpdateOpenPRTabList = window.updateOpenPRTabList || [];
    window.updateOpenPRTabList = [...prevUpdateOpenPRTabList, tab];
    resetSearchParameters();
  };

  const handleOutSideClick = (event) => {
    if (
      searchInputContainerRef.current &&
      event.target &&
      !searchInputContainerRef.current.contains(event.target)
    ) {
      resetSearchParameters();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutSideClick);
    return () => {
      document.removeEventListener('click', handleOutSideClick);
    };
  });

  return (
    <Box className="pr-search-component-container" ref={searchInputContainerRef}>
      <SearchInput
        placeholder="Search Patient Records"
        className="search-input"
        onChange={searchPatientRecord}
        value={searchText}
      />
      <div className="relative-list-container">
        <div className="patient-list-container">
          <List className={isDrawerOpen ? 'list-group border-gray' : 'd-none'}>
            {loading && (
              <ListItem>
                <Box p={0.5} width={1} textAlign="center">
                  <CircularProgress disableShrink className="primary-loader" />
                </Box>
              </ListItem>
            )}
            {!loading && patientList.length === 0 && (
              <ListItem>
                <Box width={1} textAlign="center">
                  <Typography>No Data Found</Typography>
                </Box>
              </ListItem>
            )}
            {patientList.map((patient, index) => {
              return (
                <ListItemButton
                  divider={true}
                  key={index}
                  onClick={() => selectedPatient(patient._id)}
                  sx={{ py: 1 }}>
                  <ListItemAvatar sx={{ pr: 2 }}>
                    <Avatar
                      sx={{ width: 56, height: 56 }}
                      alt={`${patient.firstName} ${patient.lastName}`}
                      src={patient.profilePic}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography className="secondary-font">{`${patient.firstName} ${patient.lastName}`}</Typography>
                    }
                    secondary={
                      <>
                        <Typography>{patient.uin && `UIN: ${patient.uin}`}</Typography>
                        <Typography>
                          {patient.dob &&
                            `DOB: ${patient.dob ? moment(patient.dob).format('Do MMM YYYY') : '-'}`}
                        </Typography>
                        <Typography>{patient.email}</Typography>
                      </>
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>
        </div>
      </div>
    </Box>
  );
};

export default PRSearchbar;
