import React from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import BBButton from './BBButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const BBModal = ({
  size,
  title,
  loading,
  okayText,
  openModal,
  okayEvent,
  cancelText,
  loadingText,
  cancelEvent,
  useOneButton,
  noFooterButtons,
  modalBodyContent,
}) => (
  <Dialog
    fullWidth
    open={openModal}
    maxWidth={size}
    onClose={cancelEvent}
    TransitionComponent={Transition}
    transitionDuration={500}>
    <DialogTitle>
      {title}
      <IconButton
        aria-label="close"
        size="small"
        sx={{ position: 'absolute', right: 4 }}
        onClick={cancelEvent}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>{modalBodyContent}</DialogContent>
    {!noFooterButtons && (
      <DialogActions>
        {!useOneButton && (
          <React.Fragment>
            <BBButton
              onClick={okayEvent}
              text={okayText}
              loadingText={loadingText}
              loading={loading}
            />
            &nbsp;&nbsp;
          </React.Fragment>
        )}
        <Button onClick={cancelEvent}>{cancelText}</Button>
      </DialogActions>
    )}
  </Dialog>
);

BBModal.propTypes = {
  title: PropTypes.string.isRequired,
  okayText: PropTypes.string,
  cancelText: PropTypes.string,
  modalBodyContent: PropTypes.any,
  okayEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  openModal: PropTypes.bool,
  useOneButton: PropTypes.bool,
  noFooterButtons: PropTypes.bool,
};

export default BBModal;
