import * as serverApi from './server';

//Patient CRUD
export const getPatient = (payload) => serverApi.get('/patient', payload);
export const addPatient = (payload) => serverApi.post('/patient', payload);
export const editPatient = ({ patientId, ...payload }) =>
  serverApi.put(`/patient/${patientId}`, payload);
export const setPatientDeleteStatus = ({ patientId, ...payload }) =>
  serverApi.put(`/patient/${patientId}/status`, payload);
export const unsubscribeMarketing = ({ patientId, ...payload }) =>
  serverApi.post(`/patient/${patientId}/unsubscribe`, payload);

//Patient Medical History
export const getMedicalHistory = (payload) => serverApi.get('/medicalhistory', payload);
export const addMedicalHistory = (payload) => serverApi.post('/medicalhistory', payload);
export const deleteMedicalHistory = ({ medicalHistoryId, ...payload }) =>
  serverApi.del(`/medicalhistory/${medicalHistoryId}`, payload);

//Patient Consent Forms
export const getConsentForms = (payload) => serverApi.get('/consentforms', payload);
export const submitConsentForm = (payload) => serverApi.post('/consentforms', payload);
export const deleteConsentForm = ({ consentFormId, ...payload }) =>
  serverApi.del(`/consentforms/${consentFormId}`, payload);

//Patient logs and notifications
export const getPatientPayments = ({ patientId, ...payload }) =>
  serverApi.get(`/patient/${patientId}/payments`, payload);
export const getNotificationLogs = ({ patientId, ...payload }) =>
  serverApi.get(`/users/patients/${patientId}/notificationlogs`, payload);
export const notifyPatient = ({ patientId, ...payload }) =>
  serverApi.post(`/users/patients/${patientId}/notify`, payload);
export const getPatientLogs = ({ patientId, ...payload }) =>
  serverApi.get(`/users/patients/${patientId}/accesslogs`, payload);
export const setPatientLog = ({ patientId, ...payload }) =>
  serverApi.post(`/users/patients/${patientId}/accesslogs`, payload);
export const checkPatientWarnings = ({ patientId, ...payload }) =>
  serverApi.get(`/patient/${patientId}/warning-check`, payload);

//Patient key performance marketing
export const setPatientMarketingStatus = ({ patientId, ...payload }) =>
  serverApi.put(`/patient/${patientId}/marketing-status`, payload);
export const getPatientMarketingNotes = ({ patientId, ...payload }) =>
  serverApi.get(`/patient/${patientId}/marketing-notes`, payload);
export const addPatientMarketingNote = ({ patientId, ...payload }) =>
  serverApi.put(`/patient/${patientId}/marketing-notes`, payload);
export const deletePatientMarketingNote = ({ patientId, noteId, ...payload }) =>
  serverApi.del(`/patient/${patientId}/marketing-notes/${noteId}`, payload);
