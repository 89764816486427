import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, FormLabel, FormControl } from '@mui/material';
import BBPasswordInput from '../UI/BBPasswordInput';
import BBButton from '../UI/BBButton';

const ResetPasswordForm = (props) => {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(
          8,
          'Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase'
        )
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,
          'Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase'
        )
        .required('Please enter the Password'),
      confirmPassword: Yup.string()
        .min(
          8,
          'Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase'
        )
        .required('Please enter the Confirm Password')
        .oneOf([Yup.ref('password')], 'Both Passwords should match'),
    }),
    onSubmit: async (values) => {
      await props.onFormSubmit(values);
    },
  });
  return (
    <Box
      mx="auto"
      component="form"
      display="flex"
      flexDirection="column"
      alignItems="center"
      onSubmit={formik.handleSubmit}>
      <FormControl fullWidth>
        <FormLabel required>Enter New Password</FormLabel>
        <BBPasswordInput
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </FormControl>
      <FormControl style={{ marginTop: 24 }} fullWidth>
        <FormLabel required>Confirm New Password</FormLabel>
        <BBPasswordInput
          id="confirmPassword"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        />
      </FormControl>
      <Box mt={3}>
        <BBButton text="Submit" loadingText="Submitting..." loading={formik.isSubmitting} />
      </Box>
      <Box className="error">{props.serverErrorMsg}</Box>
    </Box>
  );
};

export default ResetPasswordForm;

ResetPasswordForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string,
};
