import PropTypes from 'prop-types';
import { Box, TextField, FormLabel } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import appRoutes from '../../routes/appRoutes';
import BBPasswordInput from '../UI/BBPasswordInput';
import BBButton from '../UI/BBButton';

const LoginForm = (props) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(7, 'Email must be of minimum length 7')
        .email('Please provide a valid Email Address')
        .required('Please enter the Email Address'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters long')
        .required('Please enter the Password'),
    }),
    onSubmit: async (values) => {
      await props.onFormSubmit(values);
    },
  });

  return (
    <Box
      mx="auto"
      p={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      component="form"
      onSubmit={formik.handleSubmit}>
      <Box width={1} mb={3}>
        <FormLabel required>Email Address</FormLabel>
        <TextField
          id="email"
          name="email"
          type="text"
          fullWidth
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email && formik.errors.email}
        />
      </Box>
      <Box width={1} mb={3}>
        <FormLabel required>Password</FormLabel>
        <BBPasswordInput
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password && formik.errors.password}
        />
      </Box>
      <Box px={1} mb={3} width={1} display="flex" justifyContent="flex-end" alignItems="center">
        <NavLink
          to={appRoutes.forgotPassword}
          style={{
            color: '#999',
            fontSize: '14px',
            textDecoration: 'none',
          }}
          href="/">
          <span>Forgot your password?</span>
        </NavLink>
      </Box>
      <br />
      <BBButton text="Login" loadingText="Logging in..." loading={formik.isSubmitting} />
      <br />
    </Box>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string,
};
