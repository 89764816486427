import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Loading from '../components/UI/Loading';
import { unsubscribeMarketing } from '../api/patients';

const OptOutMarketingPage = () => {
  const { id } = useParams();
  const [clinic, setClinic] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = async () => {
      try {
        const encodedId = encodeURIComponent(id);
        const res = await unsubscribeMarketing({ patientId: encodedId });
        setClinic(res.data.defaultClinicId.name);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    unsubscribe();
  }, [id]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height={'100vh'}>
      {loading ? (
        <Loading />
      ) : (
        <Box textAlign={'center'}>
          {!error ? (
            <Typography variant="h4">
              You have successfully Unsubscribed from {clinic} promotional marketing
            </Typography>
          ) : (
            <Typography variant="h4">Something went wrong. Please try again.</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OptOutMarketingPage;
