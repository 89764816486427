import { lazy } from 'react';
import appRoutes from './appRoutes';
const ContactDetailsForm = lazy(
  () => import('../components/PR/ContactDetailsForm/ContactDetailsForm')
); // PR first page, patient contact detail page
const ConsentForm = lazy(() => import('../components/PR/ConsentForms/ConsentForm'));
const AddNewConsent = lazy(() => import('../components/PR/ConsentForms/AddNewConsentForm'));
const MedicalHistory = lazy(() => import('../components/PR/MedicalHistory/MedicalHistory')); // PR Medical History tab page
const S4DrugProtocolsList = lazy(
  () => import('../components/PR/S4DrugProtocols/S4DrugProtocolTable')
); // Drug protocol page PR
const AddS4DrugProtocolFrom = lazy(
  () => import('../components/PR/S4DrugProtocols/AddS4DrugProtocolForm')
); // Add S4 drug protocol form
const ViewS4DrugProtocol = lazy(
  () => import('../components/PR/S4DrugProtocols/ViewS4DrugProtocol')
); // View S4 drug protocol from view button click from table list

const OtherProtocolsList = lazy(() => import('../components/PR/OtherProtocols/OtherProtocolTable')); // Drug protocol page PR
const AddOtherProtocol = lazy(() => import('../components/PR/OtherProtocols/AddOtherProtocol')); // Add S4 drug protocol form
const ViewOtherProtocol = lazy(() => import('../components/PR/OtherProtocols/ViewOtherProtocol')); // View S4 drug protocol from view button click from table list
const BookingPage = lazy(() => import('../pages/Booking/Booking')); // booking page
const EditBookingPage = lazy(() => import('../pages/Booking/EditBooking')); // edit booking page
const ViewBookingPage = lazy(() => import('../pages/Booking/ViewBooking')); // view booking page
const PractitionerConsentsPage = lazy(
  () => import('../pages/PractitionerConsents/PractitionerConsents')
);
const EditPractitionerConsents = lazy(
  () => import('../pages/PractitionerConsents/EditPractitionerConsents')
);
const AddPaymentPage = lazy(() => import('../pages/AddPayment')); // Nurse payment

const PRRoutes = [
  { path: `${appRoutes.prDashboard}/:id`, element: <ContactDetailsForm /> },
  { path: appRoutes.prConsentForms, element: <ConsentForm /> },
  { path: appRoutes.prAddNewConsentForm, element: <AddNewConsent /> },
  { path: appRoutes.prMedicalHistory, element: <MedicalHistory /> },
  { path: appRoutes.prS4DrugProtocols, element: <S4DrugProtocolsList /> },
  { path: appRoutes.prAddS4DrugProtocolForm, element: <AddS4DrugProtocolFrom /> },
  { path: appRoutes.prViewS4DrugProtocol + '/:id', element: <ViewS4DrugProtocol /> },
  { path: appRoutes.prOtherProtocols, element: <OtherProtocolsList /> },
  { path: appRoutes.prAddOtherProtocol, element: <AddOtherProtocol /> },
  { path: appRoutes.prViewOtherProtocol + '/:id', element: <ViewOtherProtocol /> },
  { path: appRoutes.prPractitionerConsents, element: <PractitionerConsentsPage /> },
  { path: appRoutes.prEditPractitionerConsents, element: <EditPractitionerConsents /> },
  { path: appRoutes.prBookingHistory, element: <BookingPage /> },
  { path: appRoutes.prEditBookingHistory + '/:id', element: <EditBookingPage /> },
  { path: appRoutes.prViewBookingHistory + '/:id', element: <ViewBookingPage /> },
  { path: appRoutes.prCreateBooking, element: <EditBookingPage /> },
  { path: appRoutes.prPayment + '/:id', element: <AddPaymentPage /> },
];

export default PRRoutes;
