import { useSnackbar } from 'notistack';

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showToast = (message, type = 'default') => {
    let variant;
    switch (type) {
      case 'error':
        variant = 'error';
        break;
      case 'warning':
        variant = 'warning';
        break;
      case 'success':
        variant = 'success';
        break;
      case 'info':
        variant = 'info';
        break;
      default:
        variant = 'default';
    }
    enqueueSnackbar(message, { variant });
  };

  return {
    showToast,
  };
};

export default useToast;
