import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { store, persistor } from './reduxStore/store';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'react-multi-carousel/lib/styles.css';
import { SnackbarProvider } from 'notistack';
import AppRoutes from './routes';
import { theme } from './theme';
import AuthProvider from './context/AuthContext';

function App() {
  useEffect(() => {
    try {
      window.onbeforeunload = function () {
        const openPRTabList = window.updateOpenPRTabList || [];
        openPRTabList.forEach((openPRTab) => {
          openPRTab.close();
        });
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Something wrong during closing all the tabs from main file: ', e);
    }
  }, []);

  return (
    <div>
      <AuthProvider>
        <SnackbarProvider
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <AppRoutes />
                </LocalizationProvider>
              </PersistGate>
            </Provider>
          </ThemeProvider>
        </SnackbarProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
