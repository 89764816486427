import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../routes/appRoutes';
import { Avatar, Divider, Menu, MenuItem, Stack, ListItemIcon } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { userTypeMapping } from '../../utils/constants/common';
import LogOutModal from './LogOutModal';
import { useAuth } from '../../context/AuthContext';

const UserMenu = () => {
  const { user } = useAuth();
  const headClinicName = useSelector((state) => state.commonReducers.headClinicData?.name);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogoutConfirmModal, setOpenLogoutConfirmModal] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const goToProfile = () => {
    navigate(appRoutes.nurseProfile);
  };
  const goToChangePassword = () => {
    navigate(appRoutes.changePassword);
  };
  const openLogoutModal = () => {
    setOpenLogoutConfirmModal(true);
  };
  const handleMenuAction = (action) => {
    setAnchorEl(null);
    setTimeout(action, 0);
  };

  return (
    <>
      <Avatar
        onClick={handleClick}
        src={user?.profilePic}
        alt={`${user?.displayFirstName} ${user?.lastName}`}
      />
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: 'user-menu' }}>
        <MenuItem disableRipple autoFocus classes={{ root: 'no-hover' }}>
          <Avatar
            alt={`${user?.displayFirstName} ${user?.lastName}`}
            src={user?.profilePic}
            sx={{ width: 72, height: 72 }}
          />
          &nbsp;&nbsp;&nbsp;
          <Stack>
            <span className="secondary-font">{`${user?.displayFirstName} ${user?.lastName}`}</span>
            <span>{userTypeMapping[user?.userType]}</span>
            <span>{headClinicName}</span>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem className="secondary-font" onClick={() => handleMenuAction(goToProfile)}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          My Profile
        </MenuItem>
        <MenuItem className="secondary-font" onClick={() => handleMenuAction(goToChangePassword)}>
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem className="secondary-font" onClick={() => handleMenuAction(openLogoutModal)}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <LogOutModal
        openModal={openLogoutConfirmModal}
        onClose={() => setOpenLogoutConfirmModal(false)}
      />
    </>
  );
};

export default UserMenu;
