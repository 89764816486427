import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

// containers
import LoginForm from '../components/Auth/LoginForm';
import PublicLayout from '../layouts/PublicLayout';

// images
import LogoImg from '../assets/images/BeautyBookLogo.svg';

// app routes
import appRoutes from '../routes/appRoutes';

import { login } from '../api/auth';
import * as storageApi from '../api/storage';

import { USER_TYPE } from '../utils/constants/common';
import { useAuth } from '../context/AuthContext';
import useToast from '../hooks/useToast';

const LoginPage = () => {
  const { showToast } = useToast();
  const { setIsAuthenticated, setUser } = useAuth();
  const [serverMsg, setServerMsg] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (formValues) => {
    try {
      const res = await login(formValues);
      const { token, user } = res.data;
      storageApi.set('token', token);
      storageApi.set('user', JSON.stringify(user));
      setIsAuthenticated(token);
      setUser(user);
      if (user.userType === USER_TYPE.SUPER_ADMIN) {
        navigate(
          user.forcePasswordChange
            ? `/superadmin/${appRoutes.updatePassword}`
            : `/superadmin/${appRoutes.logins}`
        );
      } else if (user.userType === USER_TYPE.DOCTOR) {
        navigate(
          user.forcePasswordChange
            ? `/${user.userType}/${appRoutes.updatePassword}`
            : `/${user.userType}/${appRoutes.practitionerConsents}`
        );
      } else {
        navigate(
          user.forcePasswordChange
            ? `/${user.userType}/${appRoutes.updatePassword}`
            : `/${user.userType}/${appRoutes.bookings}`
        );
      }
      showToast('User logged in successfully', 'success');
    } catch (error) {
      setServerMsg('Incorrect Username / Password');
      setTimeout(() => {
        setServerMsg('');
      }, 5000);
    }
  };

  return (
    <PublicLayout>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="login-page-container mb-5">
        <Box className="lg-page-inner-container">
          <Box className="lp-header">
            <Box width={1}>Login</Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="lp-content-container">
            <Box mb={1} width={1} textAlign="center" className="logo-img-container">
              <img style={{ width: '60%' }} src={LogoImg} alt="logo-img" />
            </Box>
            <Box mt={2} textAlign="center" className="error">
              <p>{serverMsg}</p>
              {serverMsg && (
                <p>
                  Contact Support on 07 3555 5888
                  <br />
                  if you require assistance
                </p>
              )}
            </Box>
            <Box width={1} className="lg-form-container">
              <LoginForm onFormSubmit={handleLogin} />
            </Box>
          </Box>
        </Box>
      </Box>
    </PublicLayout>
  );
};

export default LoginPage;
