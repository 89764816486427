import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

// Containers
import PublicLayout from '../layouts/PublicLayout';
import ForgotPasswordForm from '../components/Auth/ForgotPasswordForm';

// Images
// import LogoImg from '../../assets/images/ITLogo_web.svg';
import LogoImg from '../assets/images/BeautyBookLogo.svg';

// App routes
import appRoutes from '../routes/appRoutes';
import { forgotPassword } from '../api/auth';
import ForgotPasswordModal from './ForgotPasswordModal';

const ForgotPassword = () => {
  const [isEmailSentModal, setIsEmailSentModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleForgotPasswordForm = async (formVal) => {
    try {
      await forgotPassword(formVal);
      setIsEmailSentModal(true);
    } catch (error) {
      setErrorMessage(error.data.message);
      setTimeout(() => {
        setErrorMessage('');
      }, 4500);
    }
  };

  const handleModalClose = () => {
    setIsEmailSentModal(false);
    navigate(appRoutes.login);
  };

  return (
    <PublicLayout>
      <Box
        mb={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="login-page-container">
        <Box className="lg-page-inner-container forgot-pass-page">
          <div className="lp-header">
            <Box>Forgot Password</Box>
          </div>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="lp-content-container">
            <Box width={1} textAlign="center" className="logo-img-container">
              <img style={{ width: '50%' }} src={LogoImg} alt="logo-img" />
            </Box>
            <Box width={1} textAlign="center" className="lg-form-container">
              <ForgotPasswordForm
                onFormSubmit={handleForgotPasswordForm}
                serverErrorMsg={errorMessage}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <ForgotPasswordModal open={isEmailSentModal} onClose={handleModalClose} />
    </PublicLayout>
  );
};

export default ForgotPassword;
