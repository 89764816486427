import { Box, Button, CircularProgress } from '@mui/material';

const BBButton = ({ text, loadingText, loading, ...props }) => {
  return (
    <Button type="submit" variant="contained" color="primary" disabled={loading} {...props}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <CircularProgress color="secondary" size={16} />
          <Box ml={1}>{loadingText || text}</Box>
        </Box>
      ) : (
        text
      )}
    </Button>
  );
};

export default BBButton;
