import React, { useState } from 'react';
import { Box } from '@mui/material';

// containers
import PublicLayout from '../layouts/PublicLayout';
import ResetPasswordForm from '../components/Auth/ResetPasswordForm';

// images
import LogoImg from '../assets/images/BeautyBookLogo.svg';

// app routes
import appRoutes from '../routes/appRoutes';

import { resetPassword } from '../api/auth';
import { useNavigate, useParams } from 'react-router-dom';
import ResetPasswordModal from './ResetPasswordModal';

const ResetPassword = () => {
  const { token } = useParams();
  const [isEmailResetModal, setIsEmailResetModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const navigate = useNavigate();

  const handleResetPasswordForm = async (formVal) => {
    try {
      await resetPassword({ token, ...formVal });
      setIsEmailResetModal(true);
      setModalText(null);
    } catch (error) {
      setIsEmailResetModal(true);
      setModalText('Failed to reset password, try again');
    }
  };

  const handleModalClose = () => {
    setIsEmailResetModal(false);
    navigate(appRoutes.login);
  };

  return (
    <PublicLayout>
      <Box
        mb={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="login-page-container">
        <Box className="lg-page-inner-container forgot-pass-page">
          <div className="lp-header">Reset Password</div>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="lp-content-container">
            <Box width={1} textAlign="center" className="logo-img-container">
              <img style={{ width: '50%' }} src={LogoImg} alt="logo-img" />
            </Box>
            <Box width={1} className="lg-form-container">
              <ResetPasswordForm onFormSubmit={handleResetPasswordForm} serverErrorMsg="" />
            </Box>
          </Box>
        </Box>
      </Box>
      <ResetPasswordModal
        open={isEmailResetModal}
        onClose={handleModalClose}
        modalText={modalText}
      />
    </PublicLayout>
  );
};

export default ResetPassword;
